import { useUserContext } from './UserContext'
import { useAuthenticationContext } from './AuthenticationContext'
import { useCallback, useState } from 'react'
import { Avatar, IconButton, Menu, MenuItem } from '@mui/material'
import NoAccountsIcon from '@mui/icons-material/NoAccounts'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { GoSchool } from '@goschool/routing'

export function AuthButton() {
  const { user, goSchoolUser, state } = useUserContext()
  const { signIn, signOut } = useAuthenticationContext()
  const navigate = useNavigate()

  const handleSignOut = useCallback(() => {
    signOut()
    navigate(GoSchool.home)
  }, [navigate, signOut])

  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null)

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setMenuAnchor(null)
  }

  if (state==='pending' || goSchoolUser===undefined) {
    return null
  }

  if (user==null) {
    return <IconButton edge="end" size="small" onClick={signIn}><NoAccountsIcon /></IconButton>
  } else {
    return <>
      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
        <Avatar
          sx={{ width: '24px', height: '24px' }}
          alt={user.displayName ?? ''}
          src={user.photoURL ?? undefined}
        />
      </IconButton>
      <Menu
        anchorEl={menuAnchor} keepMounted
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(menuAnchor)} onClose={handleCloseUserMenu}>
        <MenuItem key="signout" onClick={handleSignOut}>
          <Trans i18nKey="auth:signOut.cta" />
        </MenuItem>
      </Menu>
    </>
  }
}
