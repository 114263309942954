
export const Routing = {
  home: '/',
  courses: '/courses',
  termsOfUse: '/terms-of-use',
  privacyStatement: '/privacy-statement',
  invitation: '/invitations/:invitationId',
  organization: '/organizations/:organizationId',
  course: '/organizations/:organizationId/courses/:courseId',
  document: '/organizations/:organizationId/courses/:courseId/documents/:documentId',
  courseChat: '/organizations/:organizationId/courses/:courseId/chat',
  courseStudents: '/organizations/:organizationId/courses/:courseId/students',
  courseChats: '/organizations/:organizationId/courses/:courseId/chats',
  courseChatView: '/organizations/:organizationId/courses/:courseId/chats/:chatId',
  admin: '/admin',
  invitations: 'invitations',
}

export * as GoSchool from './routes'



