import { Outlet, Route, Routes } from 'react-router-dom'
import { Routing } from '@goschool/routing'
import { InvitationPage, InvitationsPage } from '@goschool/invite'
import { AdminPage, CoursePage, CoursesPage, CourseStudentsPage } from '@goschool/courses'
import { CourseDocumentPage } from '@goschool/courses'
import { CourseChatPage, CourseChatsPage, CourseChatViewPage } from '@goschool/chats'
import { TermsOfUse, PrivacyStatement } from './legal'
import { SentryReportingErrorBoundary } from './SentryReportingErrorBoundary'
import { AllChatsPage } from '@goschool/chats'

function ErrorBoundaryLayout() {
  return <SentryReportingErrorBoundary>
    <Outlet />
  </SentryReportingErrorBoundary>
}

export interface GoSchoolRoutesProps{
  App: React.ComponentType,
  NotFound: React.ComponentType
}

export function GoSchoolRoutes({App, NotFound}: GoSchoolRoutesProps) {
  return <SentryReportingErrorBoundary>
    <Routes>
      <Route index={true} element={<App />} />
      <Route path={Routing.admin}>
        <Route index={true} element={<AdminPage />} />
        <Route path={Routing.invitations} element={<InvitationsPage />} />
        <Route path="chats" element={<AllChatsPage />} />
      </Route>
      <Route path={Routing.termsOfUse} element={<TermsOfUse />} />
      <Route path={Routing.privacyStatement} element={<PrivacyStatement />} />
      <Route path={Routing.invitation} element={<InvitationPage />} />
      <Route path={Routing.courses} element={<CoursesPage />} />
      <Route path={Routing.course} element={<CoursePage />} />
      <Route path={Routing.document} element={<CourseDocumentPage />} />
      <Route path={Routing.courseChat} element={<CourseChatPage />} />
      <Route path={Routing.courseChatView} element={<CourseChatViewPage />} />
      <Route path={Routing.courseStudents} element={<CourseStudentsPage />} />
      <Route path={Routing.courseChats} element={<CourseChatsPage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </SentryReportingErrorBoundary>
}

