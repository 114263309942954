import {
  addDoc,
  collection,
  serverTimestamp,
  Timestamp
} from 'firebase/firestore'
import type { GoSchoolUserRole } from '@goschool/model'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle, FormControlLabel, FormHelperText,
  Grid2 as Grid,
  Switch,
  TextField, Typography
} from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import type { ChangeEvent, FormEvent } from 'react'
import { useState } from 'react'
import { useCallback } from 'react'
import { GoSchoolDialog } from '@goschool/mui'
import { useFirestore } from '@progos/firebase-chat'
import { useUserContext } from '@goschool/auth'


interface InvitationFormFields {
  is_personal: boolean,
  email: string | null
  name: string | null
  message: string | null

  does_expire: boolean
  expires_in: number | null
}


interface FieldErrors {
  email: string | null;
  name: string | null;
  message: string | null;
  roles: GoSchoolUserRole[] | null;
  expires_in: string | null;
  organization: string | null;
  course: string | null;
}


interface CreateInvitationDialogProps {
  displayed: boolean;
  hide: () => void;
}

export function CreateInvitationDialog({ displayed, hide }: CreateInvitationDialogProps) {
  return <GoSchoolDialog open={displayed} maxWidth="md" fullWidth={true} onClose={hide}>
    <InvitationDialog hide={hide} />
  </GoSchoolDialog>
}

function validateForm(fields: InvitationFormFields, t: (key: string) => string): {
  fieldErrors: FieldErrors,
  formError: string | null
} | null {
  const fieldErrors: FieldErrors = {
    email: null,
    name: null,
    message: null,
    roles: null,
    expires_in: null,
    organization: null,
    course: null
  }

  let formError: string | null = null
  let hasErrors = false
  if (!fields.does_expire && !fields.is_personal) {
    formError = t('auth:invitation.form.errors.noExpiration')
    hasErrors = true
  }
  if (fields.is_personal) {
    if (fields.email==null || fields.email.trim().length===0) {
      fieldErrors.email = t('auth:invitation.form.errors.noEmail')
      hasErrors = true
    }

    if (fields.name==null || fields.name.trim().length===0) {
      fieldErrors.name = t('auth:invitation.form.errors.noName')
      hasErrors = true
    }
  }

  return hasErrors ? { fieldErrors, formError }:null
}

function convertForm(fields: InvitationFormFields) {
  const millisPerDay = 1000 * 60 * 60 * 24
  const expires_at = fields.does_expire && fields.expires_in!=null
    ? Timestamp.fromDate(new Date(Date.now() + fields.expires_in * millisPerDay))
    :null
  if (fields.is_personal) {
    return {
      email: fields.email!,
      name: fields.name!,
      message: fields.message,
      created_at: serverTimestamp(),
      roles: ['student'],
      expires_at,
      course: null,
      organization: null
    }
  }
  return {
    created_at: serverTimestamp(),
    roles: ['student'],
    expires_at,
    course: null,
    organization: null
  }
}

export function InvitationDialog({ hide }: { hide: () => void }) {
  const [fields, setFields] = useState<InvitationFormFields>({
    is_personal: false,
    does_expire: true,
    email: null,
    name: null,
    message: null,
    expires_in: 7
  })
  const { user } = useUserContext()

  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({
    email: null,
    name: null,
    message: null,
    roles: null,
    expires_in: null,
    organization: null,
    course: null
  })

  const [error, setError] = useState<string | null>(null)
  const { t } = useTranslation()

  const firestore = useFirestore()
  const submit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()

      setFieldErrors({
        email: null, name: null, message: null, roles: null,
        expires_in: null, organization: null, course: null
      })
      setError(null)
      const validation = validateForm(fields, t)
      if (validation!=null) {
        setFieldErrors(validation.fieldErrors)
        setError(validation.formError)
        return
      }

      if (user==null) {
        throw new Error('User not authenticated')
      }
      const invitation = {
        ...convertForm(fields),
        created_by: user.uid,
        roles: ['student']
      }
      await addDoc(collection(firestore, 'invitations'), invitation)
      hide()
    }, [fields, firestore, hide, t, user]
  )


  const setField = useCallback(
    (name: keyof InvitationFormFields) =>
      (event: ChangeEvent<HTMLInputElement>) => {
        setFields((current) => ({ ...current, [name]: event.target.value }))
      },
    []
  )

  const setFieldChecked = useCallback(
    (name: keyof InvitationFormFields) =>
      (event: ChangeEvent<HTMLInputElement>) => {
        setFields((current) => ({ ...current, [name]: event.target.checked }))
      },
    []
  )


  return <form onSubmit={submit}>
    <DialogTitle><Trans i18nKey="auth:invitation.form.title" /></DialogTitle>
    <DialogContent>
      <Grid container spacing={2} rowSpacing={0} alignItems="center">
        <Grid size={{ xs: 12, lg: 2 }}>
          <FormControlLabel
            labelPlacement="end"
            label={<Typography variant="caption">
              <Trans i18nKey="auth:invitation.form.personal" />
            </Typography>
            }
            control={
              <Switch size="small" checked={fields.is_personal} onChange={setFieldChecked('is_personal')} />
            } />
        </Grid>
        <Grid size={{ xs: 12, lg: 5 }}>
          <TextField
            disabled={!fields.is_personal}
            margin={'normal'}
            size="small" fullWidth={true}
            error={fieldErrors.name!=null} helperText={fieldErrors.name}
            label={t('auth:invitation.form.fields.name')} value={fields.name}
            onChange={setField('name')} />
        </Grid>
        <Grid size={{ xs: 12, lg: 5 }}>
          <TextField
            disabled={!fields.is_personal}
            margin={'normal'} type="email"
            size="small" fullWidth={true}
            error={fieldErrors.email!=null} helperText={fieldErrors.email}
            label={t('auth:invitation.form.fields.email')} value={fields.email}
            onChange={setField('email')} />
        </Grid>
        <Grid offset={{ xs: 0, lg: 2 }} size={{ xs: 12, lg: 10 }}>
          <TextField
            disabled={!fields.is_personal}
            margin={'normal'}
            size="small" fullWidth={true} multiline={true} rows={4}
            error={fieldErrors.message!=null} helperText={fieldErrors.message}
            label={t('auth:invitation.form.fields.message')} value={fields.message}
            onChange={setField('message')} />
        </Grid>
        {error!=null && <Grid offset={{ xs: 0, lg: 2 }} size={{ xs: 12, lg: 10 }}>
          <FormHelperText error={true}>{error}</FormHelperText>
        </Grid>}
      </Grid>
    </DialogContent>
    <DialogActions>
      <LoadingButton sx={{ flexShrink: 0 }} type="submit" variant="contained" color="primary"
                     loading={false}
                     autoFocus={true} onClick={submit}>
        <Trans i18nKey="auth:invitation.form.submit" />
      </LoadingButton>
      <Button variant="outlined" sx={{ textTransform: 'inherit' }} onClick={hide} size="small"><Trans
        i18nKey="auth:invitation.form.cancel" /></Button>
    </DialogActions>
  </form>
}
